import { Controller } from "@hotwired/stimulus"
import "pannellum"

export default class extends Controller {

    static targets = ['pano','overlay','info','help'];
    static values = {
        url: String,
    }


    connect() {

        if (this.initValue) return;
        this.initValue = true;

    }

    play(event) {

        this.overlayTarget.classList.add("translate-x-full")
        this.infoTarget.classList.add("translate-x-full")

        this.overlayTarget.addEventListener('transitionend', () => {

            this.panoTarget.classList.remove("hidden")
            setTimeout(() => {

                pannellum.viewer(this.panoTarget, {
                    "type": "equirectangular",
                    "panorama": this.urlValue,
                    "autoLoad": true,
                    "autoRotate": -2,
                    "hfov": 180
                });

            }, 20)

            setTimeout(() => {

                this.helpTarget.classList.remove("hidden")

                setTimeout(() => {

                    this.helpTarget.classList.remove("opacity-0")

                    setTimeout(() => {

                        this.helpTarget.classList.add("opacity-0")

                    },2000);

                },20);

            },1000);

        }, {once: true});


    }


}