import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

    static targets = ['map'];

    static values = { entry: String }

    initialize() {
        const entry = JSON.parse(this.entryValue);

        mapboxgl.accessToken = 'pk.eyJ1Ijoic2NyaXB0c2NobWllZCIsImEiOiJjbHR6eHBnOGUwNDAyMmpuMDloeWtyM2prIn0.hWdYVEFTBIBEPHPiE0m5gg';

        var map = new mapboxgl.Map({
            container: this.mapTarget,
            style: "mapbox://styles/scriptschmied/cloegek3g002r01r0ha525bg6",
            zoom:17,
            center: [ entry.lng, entry.lat ],
            interactive: true
        });



        var el = document.createElement('div');
        var el_inner
        el_inner = document.createElement('div');
        el.className = 'marker-map';

        el.appendChild(el_inner)


        new mapboxgl.Marker(el)
            .setLngLat([ entry.lng, entry.lat ])
            .addTo(map);

        map.addControl(new mapboxgl.NavigationControl());
        map.scrollZoom.disable();
    }



}