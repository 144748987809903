import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ['container'];

    static values = {
        active: Boolean,
    }

    initialize() {

        let index = 1
        let current = 0;

        const images = this.containerTarget.querySelectorAll(".slide");
        images.forEach((image) => {
            image.style.zIndex = index;

            if(index==1) {
                image.classList.add("active")
            }
            if(index>1) {
                image.classList.add("!opacity-0")
            }
            index++;
        });

        setInterval(() => {
            current = current+1;

            if(current==images.length) {
                current=0;
            }


            const current_image = this.containerTarget.querySelector(".active");
            current_image.classList.add("!duration-[1000ms]")
            current_image.classList.add("!opacity-0");
            current_image.classList.remove("active");

            images[current].classList.add("!duration-[1000ms]")
            images[current].classList.remove("!opacity-0");
            images[current].classList.add("active");

        }, 5000);

    }


}