import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

    static targets = ['entries'];


    connect() {

        if (this.initValue) return;
        this.initValue = true;

    }

    toggle(event) {
        const currentTarget = event.target.closest(".akkordeon")

        currentTarget.classList.toggle("open")

    }


}