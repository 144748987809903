import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

    static targets = ['select','placeholder','chevron','box'];


    connect() {

        if (this.initValue) return;
        this.initValue = true;

    }

    initialize() {
        super.initialize();



        this.placeholderTarget.innerText = this.selectTarget.querySelectorAll("option")[this.selectTarget.selectedIndex].getAttribute("data-placeholder")

    }

    toggle() {
        this.chevronTarget.classList.toggle("rotate-180")
        this.boxTarget.classList.toggle("hidden")
    }

    select(event) {
        let val = this.indexInParent(event.target);
        this.selectTarget.selectedIndex = val+1
        this.toggle()

        this.placeholderTarget.innerText = this.selectTarget.querySelectorAll("option")[this.selectTarget.selectedIndex].getAttribute("data-placeholder")
    }

    change() {
        this.placeholderTarget.innerText = this.selectTarget.querySelectorAll("option")[this.selectTarget.selectedIndex].getAttribute("data-placeholder")
    }

    indexInParent(node) {
        var children = node.parentNode.childNodes;
        var num = 0;
        for (var i=0; i<children.length; i++) {
            if (children[i]==node) return num;
            if (children[i].nodeType==1) num++;
        }
        return -1;
    }

}