import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

    static targets = ['video','overlay'];


    connect() {

        if (this.initValue) return;
        this.initValue = true;

    }

    play(event) {
        this.videoTarget.classList.remove("hidden")
        setTimeout(() => {
            this.videoTarget.play()
            this.overlayTarget.remove()
        }, 20)
    }


}