import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ["body", "page", "overlay", "labelMenue", "labelSchliessen", "mobileHeader","mobileNavigationLevel1","mobileNavigationLevel2","desktopNavigationLevel1", "desktopNavigationLevel2","sidesub"];

    static values = {
        mobileheadershown: Boolean,
        mobilelevel1: Boolean,
        mobilelevel2: Boolean,
        desktoplevel1Value: Boolean,
        desktoplevel2Value: Boolean,
        prescroll: Number,
        timeout: Number
    }

    initialize() {
        this.mobileheadershownValue = false
        this.scroll()
    }

    lockpage() {
        document.querySelector("html").classList.remove("scroll-smooth")

        document.body.style.top = `-${window.scrollY}px`
        document.body.style.position = 'fixed'
        document.body.style.overflowY = 'scroll'
        document.body.style.width = '100%'
    }

    unlockpage() {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.overflowY = ''
        document.body.style.width = ''

        window.scrollTo(0, parseInt(scrollY || '0') * -1);

        setTimeout(() => {
            document.querySelector("html").classList.add("scroll-smooth")
        }, 20)
    }

    togglemobile() {
        this.mobilelevel1Value = !this.mobilelevel1Value

        if(this.mobilelevel1Value) {

            this.lockpage()

            this.pageTarget.classList.add("page-mobile-animation")
            this.mobileNavigationLevel1Target.classList.remove("hidden")
            setTimeout(() => {
                this.pageTarget.classList.add("page-mobile-animation-start")
                this.mobileHeaderTarget.classList.add("opacity-40")
                this.mobileNavigationLevel1Target.classList.remove("-translate-x-full")
            }, 20)

        }
        else {

            this.unlockpage()

            this.pageTarget.classList.remove("page-mobile-animation-start")
            this.mobileHeaderTarget.classList.remove("opacity-40")
            this.mobileNavigationLevel1Target.classList.add("-translate-x-full")

            this.mobileNavigationLevel1Target.addEventListener('transitionend', () => {
                this.pageTarget.classList.remove("page-mobile-animation")
                this.mobileNavigationLevel1Target.classList.add("hidden")
            }, {once: true});

        }
    }

    togglelevel2(event) {
        this.mobilelevel2Value = !this.mobilelevel2Value

        if(this.mobilelevel2Value) {

            const id = event.target.getAttribute("data-id");
            this.mobileNavigationLevel2Target.querySelector("[data-id='"+id+"']").classList.remove("hidden")

            this.mobileNavigationLevel2Target.classList.remove("hidden")
            setTimeout(() => {
                this.mobileNavigationLevel1Target.classList.add("-translate-x-1/3")
                this.mobileNavigationLevel1Target.classList.add("opacity-40")
                this.mobileNavigationLevel2Target.classList.remove("translate-x-full")
            }, 20)
        } else {
            this.mobileNavigationLevel1Target.classList.remove("-translate-x-1/3")
            this.mobileNavigationLevel1Target.classList.remove("opacity-40")
            this.mobileNavigationLevel2Target.classList.add("translate-x-full")
            const subs = this.mobileNavigationLevel2Target.querySelectorAll(".mobile-sub-navigation")
            subs.forEach((sub) => {
                sub.classList.add("hidden")
            });

        }
    }

    toggledesktopLevel1() {

        this.desktoplevel1Value = !this.desktoplevel1Value

        if(this.desktoplevel1Value) {



            this.labelMenueTarget.classList.add("hidden")
            this.labelSchliessenTarget.classList.remove("hidden")

            this.overlayTarget.classList.remove("hidden")
            setTimeout(() => {
                this.overlayTarget.classList.add("opacity-50")
                this.overlayTarget.classList.remove("opacity-0")
            }, 10)


            this.desktopNavigationLevel1Target.classList.add("hidden")
            this.desktopNavigationLevel1Target.classList.remove("!hidden")

            this.overlayTarget.addEventListener('transitionend', () => {
                this.desktopNavigationLevel1Target.classList.remove("-translate-x-full")
                this.desktopNavigationLevel1Target.addEventListener('transitionend', () => {
                    if(this.desktopNavigationLevel1Target.querySelectorAll(".active-parent").length) {
                        this.toggledesktopLevel2Direct(this.desktopNavigationLevel1Target.querySelectorAll(".active-parent")[0].getAttribute("data-id"));
                    }
                }, {once: true});
            }, {once: true});



        } else {

            this.labelMenueTarget.classList.remove("hidden")
            this.labelSchliessenTarget.classList.add("hidden")

            if(this.desktoplevel2Value) {

                this.desktoplevel2Value = !this.desktoplevel2Value

                this.desktopNavigationLevel2Target.classList.add("-translate-x-full")

                this.desktopNavigationLevel2Target.addEventListener('transitionend', () => {

                    this.desktopNavigationLevel2Target.classList.remove("hidden")
                    this.desktopNavigationLevel2Target.classList.add("!hidden")

                    this.toggledesktopLevel1close()

                }, {once: true});

            } else {
                this.toggledesktopLevel1close()
            }



        }

    }

    toggledesktopLevel1close(fast = false) {


        this.desktopNavigationLevel1Target.classList.add("-translate-x-full")
        this.desktopNavigationLevel1Target.addEventListener('transitionend', () => {
            this.overlayTarget.classList.remove("opacity-50")
            this.overlayTarget.classList.add("opacity-0")

            this.desktopNavigationLevel1Target.classList.remove("hidden")
            this.desktopNavigationLevel1Target.classList.add("!hidden")

            //this.desktopNavigationLevel2Target.querySelector(".desktop-sub-navigation").classList.add("hidden")

            const subs = this.desktopNavigationLevel2Target.querySelectorAll(".desktop-sub-navigation")
            subs.forEach((sub) => {
                sub.classList.add("hidden")
            });

            this.overlayTarget.addEventListener('transitionend', () => {

                this.overlayTarget.classList.add("hidden")



            }, {once: true});

        }, {once: true});

        if(fast) {
            this.overlayTarget.classList.remove("opacity-50")
            this.overlayTarget.classList.add("opacity-0")

            this.desktopNavigationLevel1Target.classList.remove("hidden")
            this.desktopNavigationLevel1Target.classList.add("!hidden")

            const subs = this.desktopNavigationLevel2Target.querySelectorAll(".desktop-sub-navigation")
            subs.forEach((sub) => {
                sub.classList.add("hidden")
            });

            this.overlayTarget.addEventListener('transitionend', () => {

                this.overlayTarget.classList.add("hidden")



            }, {once: true});
        }

    }

    toggledesktopLevel2(event) {

        const id = event.target.getAttribute("data-id");

        if(id!=this.desktoplevel2Value) {
            this.desktoplevel2Value = id
        } else {
            return
        }



        const subs = this.desktopNavigationLevel2Target.querySelectorAll(".desktop-sub-navigation")
        subs.forEach((sub) => {
            sub.classList.add("hidden")
        });

        this.desktopNavigationLevel2Target.querySelector("[data-id='"+id+"']").classList.remove("hidden")

        this.desktopNavigationLevel2Target.classList.add("hidden")
        this.desktopNavigationLevel2Target.classList.remove("!hidden")

        setTimeout(() => {
            this.desktopNavigationLevel2Target.classList.remove("-translate-x-full")
        }, 20);


    }

    toggledesktopLevel2Direct(id) {



        if(id!=this.desktoplevel2Value) {
            this.desktoplevel2Value = id
        } else {
            return
        }



        const subs = this.desktopNavigationLevel2Target.querySelectorAll(".desktop-sub-navigation")
        subs.forEach((sub) => {
            sub.classList.add("hidden")
        });

        this.desktopNavigationLevel2Target.querySelector("[data-id='"+id+"']").classList.remove("hidden")

        this.desktopNavigationLevel2Target.classList.add("hidden")
        this.desktopNavigationLevel2Target.classList.remove("!hidden")

        setTimeout(() => {
            this.desktopNavigationLevel2Target.classList.remove("-translate-x-full")
        }, 20);


    }

    showMobileHeader() {
        let window_width = window.innerWidth;

        if(window_width>=744) return;

        //if(this.mobileheadershownValue) return

        this.mobileheadershownValue = true

        this.mobileHeaderTarget.classList.remove("hidden")
        this.mobileHeaderTarget.classList.remove("-z-[100]")
        this.mobileHeaderTarget.classList.add("z-[100]")
        setTimeout(() => {
            this.mobileHeaderTarget.classList.remove("opacity-0")
        },10)
    }

    hideMobileHeader(force = false) {

        let window_width = window.innerWidth;

        if(window_width>=744 && !force) return;

        if(!this.mobileheadershownValue || this.mobilelevel1Value) return
        this.mobileheadershownValue = false

        this.mobileHeaderTarget.classList.add("opacity-0")
        this.mobileHeaderTarget.addEventListener('transitionend', () => {
            this.mobileHeaderTarget.classList.add("-z-[100]");
            this.mobileHeaderTarget.classList.remove("z-[100]");
            this.mobileHeaderTarget.classList.add("hidden");
        }, {once: true});

    }

    scroll() {
        let scroll = window.scrollY;

        if(scroll>0) {
            this.bodyTarget.classList.add("scrolled-down-general")

            this.showMobileHeader()
        } else {

            this.bodyTarget.classList.remove("scrolled-down-general")
            this.hideMobileHeader()
            setTimeout(() => {
                this.hideMobileHeader()
            }, 50)

        }
    }

    resize() {
        let window_width = window.innerWidth;
        clearTimeout(this.timeoutValue)
        if(window_width>=744) {

            this.desktopNavigationLevel1Target.classList.add("!transition-none")
            this.desktopNavigationLevel2Target.classList.add("!transition-none")

            this.timeoutValue = setTimeout(() => {
                this.desktopNavigationLevel1Target.classList.remove("!transition-none")
                this.desktopNavigationLevel2Target.classList.remove("!transition-none")
            }, 100)

            if(this.mobilelevel1Value) {
                this.togglemobile()
            }
            if(this.mobilelevel2Value) {
                this.togglelevel2()
            }
            if(this.mobileheadershownValue) {
                this.hideMobileHeader(true)
            }
        } else {
            if(this.desktoplevel1Value) {
                this.toggledesktopLevel1()
                this.toggledesktopLevel1close(true)

            }
        }
    }

}