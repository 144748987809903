import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ['outer','image'];

    static values = {
        active: Boolean,
    }

    initialize() {

        this.resize()

    }

    resize() {

        let window_width = window.innerWidth;


        if(window_width<1441) {
            this.imageTarget.style.height = null
        } else {
            let img_height = this.outerTarget.offsetHeight
            this.imageTarget.style.height = img_height+"px"

        }




    }


}