import { Controller } from "@hotwired/stimulus"

import AOS from "aos";
export default class extends Controller {

    static targets = ['overlay','popup','nav'];
    static values = {
        overlay: Boolean
    }

    connect() {

        if (this.initValue) return;
        this.initValue = true;
        this.overlayValue = false;
        this.overlayLoadedValue = false;

    }

    open(event) {

        if(this.overlayValue) return;

        this.overlayValue = true;

        const id = event.target.getAttribute("data-id")

        this.overlayTarget.classList.remove("hidden")
        setTimeout(() => {
            this.overlayTarget.classList.add("opacity-50")
            this.overlayTarget.classList.remove("opacity-0")
        }, 10)

        let window_width = window.innerWidth;
        AOS.init({ disable:true });

        this.navTarget.classList.add("!fixed")


        document.body.style.top = `-${window.scrollY}px`
        document.body.style.position = 'fixed'
        document.body.style.overflowY = 'scroll'
        document.body.style.width = '100%'

        this.overlayTarget.addEventListener('transitionend', () => {

            let url = "/ajax/veranstaltung?id="+id

            let result = this.resultTarget

            fetch(url).then((response) => {
                response.text().then((text) => {
                    document.querySelector("body").innerHTML += text
                    setTimeout(() => {
                        this.popupTarget.classList.remove("opacity-0")
                        this.overlayLoadedValue = true;
                    },50)


                })
            })


        }, {once: true});


    }

    close() {

        if(!this.overlayLoadedValue) return;

        this.overlayTarget.classList.remove("opacity-50")
        this.overlayTarget.classList.add("opacity-0")

        this.overlayTarget.addEventListener('transitionend', () => {

            this.overlayTarget.classList.add("hidden")

        }, {once: true});



        this.popupTarget.classList.add("opacity-0")
        this.popupTarget.addEventListener('transitionend', () => {

            this.popupTarget.remove()
            this.overlayValue = false;
            this.overlayLoadedValue = false;

        }, {once: true});


        let window_width = window.innerWidth;

        document.querySelector("html").classList.remove("scroll-smooth")

        // restore scrollable body
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.overflowY = ''
        document.body.style.width = ''

        this.navTarget.classList.remove("!fixed")

        setTimeout(() => {
            window.scrollTo(0, parseInt(scrollY || '0') * -1);

            setTimeout(() => {
                document.querySelector("html").classList.add("scroll-smooth")
                AOS.init({ disable:false, once:false });
            }, 200)

        }, 20)

    }



}